export enum MyAccountIds {
  MY_ACCOUNT = 'myAccount',
  MY_ORDERS = 'myOrders',
  MY_RETURNS = 'myReturns',
  MY_DEVICES = 'myDevices',
  MY_MESSAGES = 'myMessages',
  MY_TEAM = 'myTeam',
  MY_DOCUMENTS = 'myDocuments',
  MY_ACCOUNT_DETAILS = 'myAccountDetails',
}

export enum MyProductIds {
  MY_PRODUCTS = 'myProducts',
  VIEWED_PRODUCTS = 'viewedProducts',
  ORDERED_PRODUCTS = 'orderedProducts',
  ORDER_ALARM = 'orderAlarm',
  AUTO_DELIVERY = 'autoDelivery',
  MY_FAVORITE = 'myFavorite',
  FAV_LISTS = 'favLists',
}
